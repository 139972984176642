@use "../../scss/" as *;

.wrapper {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }
  * {
    box-sizing: border-box;
  }
}

.containers {
  position: relative;
  max-width: 1440px;
  margin-bottom: 100px;
  margin: auto;
  .back1 {
    position: absolute;
    top: 100px;
    left: -40px;
    transform: scale(0.9);
  }

  .arrow-left {
    position: absolute;
    bottom: 200px;
    left: 50px;
    background: #fd562a;
    border: 2px solid #000000;
    box-shadow: 0px 4px 0px #000000;
    border-radius: 60px;
    padding: 5px 20px;
  }
  .arrow-right {
    position: absolute;
    bottom: 200px;
    right: 50px;
    background: #fd562a;
    border: 2px solid #000000;
    box-shadow: 0px 4px 0px #000000;
    border-radius: 60px;
    padding: 5px 20px;
  }

  .back2 {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .labels {
    text-align: center;
    margin-bottom: 20px;
    width: 60%;
    .label-1 {
      color: $main-color;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
    .label-2 {
      color: #7153d9;
      font-style: normal;
      font-weight: 700;
      font-size: 94px;
      line-height: 1.1;
      letter-spacing: -5px;
      font-family: "Poppins";
      span {
        color: $main-color;
      }
    }
    .label-3 {
      margin-top: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: black;
    }
  }
}

.mySwiper {
  width: 75%;
  padding: 50px 0;
}

.mySwiper {
}

.swiper-slide {
}

.swiper-item {
  width: 350px;
  height: 350px;
  border: 2px solid #000000;
  box-shadow: 0px 14px 0px #000000;
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
