@use '../../scss/' as *;

.Abutton {
    border: 0;
    outline: 0;
    background-color: transparent;
    font-family: $font-main;
    font-size: 16px;
    color:#212529;
    transition: all .3s;
    padding-right: 30px;
}

.Abutton:hover {
    color: #fd562a;
}