$body-bg: #200b6a;
$second-bg: #381463;
$main-color: #fd562a;
$main-color-hover: #de3700;
$txt-color: #000;
$txt-second-color: #757474;
$white: #fff;

$font-main: "Gilroy-Bold";
$font-second: "Roboto";

$bg-item-1: #7153d9;
$bg-item-2: #4526b1;
$bg-item-3: #4c22e1;

$header-height: 170px;
$header-shrink-height: 90px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900: 1900px;

$box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
$bg-overlay: rgba(0, 0, 0, 0.5);
